import React from 'react'
import {SEO, Input, Button, IntlTelInput, Datepicker, Select} from 'components'
import {Layout, MayInterestYouCards, InsurancesCarousel, WellnessBanner} from 'widgets'
import {RedWorld, Hospital, Dentalist, Ambulance, Hotel, Pills, VectorAirplane, World, AssistCard, WebBannerAssistCard, AirplaneSeparator} from 'images'
import {IntlTelCountry, QuoteRequest} from 'models'
import {DESTINIES as Destinies, ASSISTCARD_ID, showError, setLoading, quitLoading} from 'utils'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {CandidateService} from 'services'
import {navigate} from 'gatsby'
const isBrowser = typeof window !== "undefined";
let detectMobil:boolean =  false;
  if(isBrowser){
      if ( 
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i) 
      ) {
              detectMobil = true;
          }
          else{
            detectMobil = false;
          }
          console.log('detectMobil', detectMobil);
  }

type Form = QuoteRequest
type ValidationError = {
    name?: boolean,
    lastname?: boolean,
    email?: boolean,
    country_phone?: boolean,
    country?: boolean,
    destiny_id?: boolean,
    since?: boolean,
    until?: boolean,
}
export default () => {
    const features = [
        '84 Centrales',
        '97 Países con servicio en forma directa',
        '4000 mil prestadores calificados',
        '1400 empleados en todo el mundo',
        'Atencion en 16 idiomas',
        '8 millones de clientes por año'
    ]
    const DESTINIES = Destinies.map(element => {
        return {
            label: element.es,
            value: element.slug
        }
    })
    const dispatch = useDispatch()
    const defaultAge = 18
    const IFormState: Form = {
        name: '',
        lastname: '',
        email: '',
        country_phone: '',
        country: null,
        destiny_id: DESTINIES[0].value,
        since: moment().toDate(),
        passengers: [defaultAge],
        filterById: ASSISTCARD_ID,
    }
    const [form, setForm] = React.useState<Form>(IFormState)
    const change = (key: keyof Form, value: string | Date) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    
    const defaultIntlClass = 'intl-tel-input input-purple text-dark form-control'
    const invalidIntlClass = defaultIntlClass  +' invalid-feedback '
    const [intlClassName, setIntlClassName] = React.useState<string>(defaultIntlClass)
    const [validationError, setValidationError] = React.useState<ValidationError>({})
    const onChangeIntl = (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => {
        let _form: Form = {
            ... form,
            country,
            country_phone
        }
        setForm(_form)
        if(country && country_phone){
            setIntlClassName(defaultIntlClass)
        }else{
            setIntlClassName(invalidIntlClass)
        }
    }
    
    const onSubmit = async () => {
        const keys = Object.keys(form)
        const keysToCheck: any[] = []
        keys.forEach((element) => {
            //@ts-ignore
            const value = form[element]
            if(!value)
                keysToCheck.push(element)
        })
        if(keysToCheck?.length){
            let _validationError = {... validationError}
            keysToCheck.forEach(element => {
                //@ts-ignore
                _validationError[element] = true
            })
            setValidationError(_validationError)
        }else{
            setLoading()
            try {
                const _form: Form = {
                    ... form,
                    country_phone: '+'+form.country?.dialCode+form.country_phone
                }
                const res = await CandidateService.save(_form)
                dispatch({
                    type: 'SET_QUOTE_REQUEST',
                    payload: form
                })
                navigate('/buscar?candidate='+res.id_sql)
            } catch (error:any) {
                showError(error?.response?.data?.message)
            }finally{
                quitLoading()
            }
        }
    }
    const isDisabled = (): boolean => {
        let disabled = !form.country || !form.country_phone || !form.email || !form.lastname || !form.name || !form.since || !form.until
        let _validationError = {}
        for (const key in form) {
            //@ts-ignore
            if(!form[key]){
                //@ts-ignore
                _validationError[key] = true
            }
        }
        const haveSymbol = form.email.includes('@')
        if((Object.values(_validationError).length > 0) || (form.email && !disabled && !haveSymbol)){
            disabled = true
        }
        return disabled
    }
    const onBlurField = (key: keyof ValidationError) => {
        const value = form[key]
        let _validationError = {... validationError}
        if((key === 'email' && !form.email.includes('@')) || (!value && !validationError[key])){
            _validationError[key] = true
        }else{
            delete _validationError[key]
        }
        setValidationError(_validationError)
    }

    const onFocusField = (key: keyof ValidationError) => {
        let _validationError = {... validationError}
        delete _validationError[key]
        setValidationError(_validationError)
    }
    // const addPassenger = () => {
    //     let passengers = [...(form.passengers || [])]
    //     passengers.push(defaultAge)
    //     const _form = {
    //         ... form,
    //         passengers
    //     }
    //     setForm(_form)
    // }
    // const deleteLastPassenger = () => {
    //     let passengers = [...(form.passengers || [])]
    //     passengers.splice(passengers.length-1, 1)
    //     const _form = {
    //         ... form,
    //         passengers
    //     }
    //     setForm(_form)
    // }
    // const setPassengerAge = (value: string, i: number) => {
    //     let passengers = [... (form.passengers || [])]
    //     passengers[i] = parseInt(value) || defaultAge
    //     const _form = {
    //         ... form,
    //         passengers
    //     }
    //     setForm(_form)
    // }
    return(
        <Layout>
            <SEO
                title="Assistcard - Elige aquí tu asistencia de viaje internacional"
                description="Viaja con tranquilidad: Europa, Asia, América y el mundo te esperan. Compra con Assistcard, una empresa con más de 40 años en el mercado de asistencias en viaje."
                keywords="asistencia de viaje, asistencia al viajero, seguro de viaje, comparador de asistencias de viaje, assistcard, assist card, como usar assistcard"
            />
            <div className="assist-card-landing">
                <div
                    className="info-banner"
                    style={{
                        backgroundImage: !detectMobil ? `url(${WebBannerAssistCard})` : 'none',
                        height: !detectMobil ? '100vh' : 'auto',
                        backgroundColor: '#451f69'
                    }}
                >
                    <div className="row">
                        <div className="col-md-4 col-12 col-sm-12 w-100 text-center form-container">
                            <h4 className="text-purple m-0 pt-2">Cotiza, compara y compra</h4>
                            <img
                                src={AirplaneSeparator}
                                alt="Separador"
                                className="airplane-separator"
                            />
                            <div className="row">
                                <div className="col-12">
                                    <Select
                                        name="destiny_id"
                                        value={form.destiny_id || ''}
                                        options={DESTINIES}
                                        placeholder="Destino"
                                        onChange={(value: string) => change('destiny_id', value)}
                                        required
                                        className="input-purple text-dark"
                                        hasError={validationError.destiny_id}
                                        onBlur={() => onBlurField('destiny_id')}
                                        onFocus={() => onFocusField('destiny_id')}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        name="name"
                                        value={form.name}
                                        placeholder="Nombre"
                                        onChange={(value: string) => change('name', value)}
                                        required
                                        labelClass="dark"
                                        className="input-purple"
                                        hasError={validationError.name}
                                        onBlur={() => onBlurField('name')}
                                        onFocus={() => onFocusField('name')}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        name="lastname"
                                        value={form.lastname}
                                        placeholder="Apellido"
                                        onChange={(value: string) => change('lastname', value)}
                                        required
                                        className="input-purple"
                                        hasError={validationError.lastname}
                                        onBlur={() => onBlurField('lastname')}
                                        onFocus={() => onFocusField('lastname')}
                                    />
                                </div>
                                
                                <div className="col-6">
                                    <Datepicker
                                        minDate={moment().toDate()}
                                        onChange={(value: Date) => change('since', value)}
                                        placeholder="Fecha de salida"
                                        labelColor="dark"
                                        labelClass='input-purple'
                                        value={form.since}
                                        required
                                        hasError={validationError.since}
                                        onBlur={() => onBlurField('since')}
                                        onFocus={() => onFocusField('since')}
                                    />
                                </div>
                                <div className="col-6">
                                    <Datepicker
                                        minDate={form.since}
                                        onChange={(value: Date) => change('until', value)}
                                        placeholder="Fecha de regreso"
                                        labelColor="dark"
                                        labelClass='input-purple'
                                        value={form.until}
                                        required
                                        hasError={validationError.until}
                                        onBlur={() => onBlurField('until')}
                                        onFocus={() => onFocusField('until')}
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="w-100">
                                        <IntlTelInput
                                            onChange={onChangeIntl}
                                            placeholder="WhatsApp"
                                            className={intlClassName}
                                            onPhoneNumberBlur={() => onBlurField('country_phone')}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <Input
                                        name="email"
                                        className="input-purple"
                                        type="email"
                                        value={form.email}
                                        placeholder="Email"
                                        onChange={(value: string) => change('email', value)}
                                        required
                                        hasError={validationError.email}
                                        onBlur={() => onBlurField('email')}
                                        onFocus={() => onFocusField('email')}
                                    />
                                </div>
                            </div>
                            {/* <div className="container">
                                <div className="w-100 text-center">
                                    <p className="text-dark text-left w-100">
                                        Edades de pasajeros
                                    </p>
                                    <div className="row justify-content-center">
                                        {
                                            form.passengers?.map((element: number | string, i: number) => {
                                                return(
                                                    <div
                                                        className="col-md-3 col-sm-4 col-4"
                                                        key={i}
                                                    >
                                                        <Input
                                                            name="passenger_age"
                                                            className="pr-0 input-purple"
                                                            onChange={(value: string) => {
                                                                setPassengerAge(value, i)
                                                            }}
                                                            value={element.toString()}
                                                            onBlur={() => {
                                                                if(!element){
                                                                    setPassengerAge(defaultAge.toString(), i)
                                                                }else if(element && element.toString().length > 2){
                                                                    const value = element.toString().substring(0, 2)
                                                                    setPassengerAge(value, i)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-md col-sm text-right">
                                            <Button
                                                disabled={form.passengers?.length === 1}
                                                className="link text-purple"
                                                icon="minus"
                                                onClick={() => deleteLastPassenger()}
                                            />
                                        </div>
                                        <div className="col col-md col-sm text-left">
                                            <Button
                                                disabled={form.passengers?.length === 5}
                                                className="link text-purple"
                                                icon="plus"
                                                onClick={() => addPassenger()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="w-100 text-center button-container p-2">
                                <Button
                                    label="Cotizar ahora"
                                    className="red"
                                    disabled={isDisabled()}
                                    onClick={() => onSubmit()}
                                />
                            </div>
                        </div>
                        <div className="col-md col-12 col-sm-12 w-100 text-right logo-container">
                            <img src={AssistCard} alt="Logo - Assist Card" />
                            <div className="span-container">
                                <p className="text-white font-weight-bold">
                                    Elige tu asistencia de <br /> viaje internacional <br /> con Assistcard
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="assist-card-info mt-4 mt-sm-0">
                    <h1 className="text-center w-100 m-0 p-2 text-purple">
                        ¡En Compara Assist, encuentra los mejores planes de asistencia al viajero, escoge entre los diferentes proveedores y selecciona tu oferta ideal!
                    </h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md col-sm-12 col-12">
                                <img
                                    src={AssistCard}
                                    alt="Assist Card - Proveedor de Compara Assist"
                                />
                            </div>
                            <div className="col-md col-sm-12 col-12 m-1 m-sm-0">
                                <p className="text-dark-gray text-justify">
                                    <strong>Assist Card</strong>, es una de las mayores compañías de Seguro de viaje y Asistencia al viajero con las que trabajamos. Con experiencia desde 1972, fue fundada en Suiza y ha contado con una rápida expansión en América Latina, América Central y Asia, gracias a:
                                </p>
                                <ul>
                                    {
                                        features.map((element: string, i: number) => {
                                            return(
                                                <li
                                                    key={i}
                                                >
                                                    <strong> {element} </strong>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <p className="text-dark-gray text-justify">
                                    ¡En Compara Assist, encuentra los mejores planes de asistencia al viajero, escoge entre más de 10 aseguradoras y escoge tu oferta ideal!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-to-use">
                    <div className="info m-1 m-sm-0">
                        <h3 className="text-purple text-center w-100">¿Cómo usar Assist card? Experiencia y protección en viaje?</h3>
                        <p className="text-dark-gray text-justify w-100">
                            Sus distintas coberturas cuentan con la calidad que todo viajero necesita; disponibilidad de servicio las 24 horas, los 365 días del año. Su asistencia para viaje acompaña desde una eventualidad de alta complejidad médica que pueda requerir un traslado en avión sanitario, hasta la asistencia en la recuperación del equipaje extraviado, casos de pérdida o demora de equipaje o documentos personales (pasaporte, tickets, entre otros), acompañamiento de menores que estén solos, asistencia legal y adelanto de fondos para fianzas.
                        </p>
                    </div>
                    <div className="row">
                        <div className="info-item col-12 col-md-6 col-sm-12 m-sm-0">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={Hospital}
                                        alt="Hospital - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Asistencia Médica general
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item right col-12 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={Dentalist}
                                        alt="Odontologia - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Odontología
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item col-12 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container p-3 p-sm-0">
                                    <img
                                        src={Ambulance}
                                        alt="Asistencia Médica en caso de una enfermedad preexistente - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12 p-3 p-sm-0">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Asistencia Médica en caso de una enfermedad preexistente
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item right col-12 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={Hotel}
                                        alt="Gastos de hotel por convalencencia - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Gastos de hotel por convalencencia
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item col-12 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={Pills}
                                        alt="Medicamentos - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Medicamentos
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item right col-12 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={VectorAirplane}
                                        alt="Repatriación médica y funeraria - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Repatriación médica y funeraria
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="info-item col-12 ">
                            <div className="row">
                                <div className="col-md col-12 col-sm-12 text-right image-container">
                                    <img
                                        src={World}
                                        alt="Regreso adelantado por siniestro en el hogar - Compara Assist"
                                    />
                                </div>
                                <div className="text col-md col-12 col-sm-12">
                                    <h3 className="text-dark-gray font-weight-bold">
                                        Regreso adelantado <br /> por siniestro en el hogar
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="assist-card-into-world">
                    <h2 className="text-purple font-weight-bold text-center">
                        ASSIST CARD&nbsp;
                        <span className="text-dark-gray">en el mundo</span>
                    </h2>
                    {/* <span style={{
                        width: 120,
                        height: 3,
                        backgroundColor: '#777e88'
                    }}></span>
                    <span style={{
                        width: 100,
                        height: 3,
                        backgroundColor: '#451f69'
                    }}></span>
                    <span style={{
                        width: 120,
                        height: 3,
                        backgroundColor: '#777e88'
                    }}></span> */}
                    <img
                        src={RedWorld}
                        alt=""
                    />
                </div>
                <div className="w-100 text-center">
                    <h3 className="font-weight-bold text-center text-purple m-0 p-2">
                        ¡Te ofrecemos las mejores asistencias de viaje de forma rápida y segura!
                    </h3>
                    <h3 className="font-weight-bold text-center text-purple m-0 p-2">
                        Comunícate con nuestros asesores mediante chat o líneas telefónicas.
                    </h3>
                </div>
                <WellnessBanner />
                <div className="insurance-carousel p-2">
                    <div className="container">
                        <InsurancesCarousel />
                    </div>
                </div>
                <MayInterestYouCards />
            </div>
        </Layout>
    )
}